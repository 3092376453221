import styled from "styled-components";
import Flex from "src/components/UI/Structure/Flex";

const HeroStyles = styled(Flex)`
  position: relative;
  width: 100%;
  top: 0;
  z-index: 0;
  min-height: ${(props) =>
    (props.size === "small" && props.theme.dimensions.hero.small) ||
    (props.size === "medium" && props.theme.dimensions.hero.medium) ||
    (props.size === "large" && props.theme.dimensions.hero.large) ||
    "none"};
  color: ${(props) =>
    props.images ? props.theme.colors.light : props.theme.colors.dark};
  overflow: hidden;

  &::after {
    ${(props) => props.images && props.theme.mixins.positionCover()};
    ${(props) => props.images && props.theme.mixins.overlay()};
    z-index: 2;
  }

  .hero-image {
    overflow: hidden;
    z-index: 1;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    ${(props) => props.theme.mixins.positionCover()};

    > * {
      ${(props) => props.theme.mixins.positionCover()};
      ${(props) => props.theme.mixins.imageObjectFit()};
    }

    img,
    picture {
      /* ${(props) => props.theme.mixins.positionCover()}; */
    }
  }

  .label {
    position: absolute;
    bottom: ${(props) => props.theme.space[4]};
    right: ${(props) => props.theme.space[4]};
    opacity: 0.45;
  }
`;

export default HeroStyles;
