const getPreviewImage = (media) => {
  if (typeof media === "string") {
    return media;
  }
  if (
    media &&
    media["wp:featuredImage"] &&
    media["wp:featuredImage"][0].mediaDetails.sizes.full.source_url
  ) {
    return media["wp:featuredImage"][0].mediaDetails.sizes.full.source_url;
  }
  if (media && media.url) {
    return media.url;
  }
  return null;
};

export default getPreviewImage;
