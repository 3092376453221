import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const IconStyles = styled.div`
  display: inline-block;
  width: ${props =>
    (props.size === 'large' && '84px') ||
    (props.size === 'small' && '30px') ||
    '30px'};

  ${media.lg`
  width: ${props =>
    (props.size === 'large' && '84px') ||
    (props.size === 'small' && '30px') ||
    '48px'};
    `}

  svg {
    width: 100%;
    height: auto;
  }
`

const Icon = props => <IconStyles {...props}>{props.children}</IconStyles>

export default Icon
