import React from "react";
import url from "url";
import he from "he";

import Image from "src/components/UI/Image";
import Link from "src/components/UI/Link";
import Title from "src/components/UI/Typography/Title";
import Label from "src/components/UI/Typography/Label";
import GridStyles from "src/components/styles/GridStyles";

const ArtistItem = ({ overlay, mb = 2, country, image, link, name }) => {
  const nameDecoded = he.decode(name);
  return (
    <GridStyles mb={mb} overlay={overlay && overlay.toString()} image="true">
      <Link to={link ? url.parse(link).pathname : ""} title={nameDecoded}>
        {image && (
          <div className="grid-image">
            <Image image={image} alt={nameDecoded} />
          </div>
        )}
        <div className="grid-content">
          <div className="grid-header" />
          <div className="grid-body animated-content">
            <Title as="h1" className="grid-title">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </Title>
          </div>
          <div className="grid-footer">
            {country && country !== "none" && (
              <Label className="grid-label">({country})</Label>
            )}
          </div>
        </div>
      </Link>
    </GridStyles>
  );
};

export default ArtistItem;
