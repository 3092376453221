import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import Box from "src/components/UI/Structure/Box";

const GridStyles = styled(Box)`
  ${(props) => props.theme.mixins.transition([{ property: "box-shadow" }])};
  position: relative;
  width: 100%;
  position: relative;
  outline: 2px solid black;
  outline-offset: -2px;
  padding-bottom: ${(props) => props.theme.dimensions.aspectRatio.portrait};
  box-shadow: none;
  overflow: hidden;

  ${media.md`
    padding-bottom: ${(props) => props.theme.dimensions.aspectRatio.box};
  `}

  &:hover {
    box-shadow: inset 0px 0px 0px 4px black;
  }

  .grid-header {
  }

  .grid-body {
    margin: 0;
  }

  .grid-footer {
  }

  .grid-image {
    ${(props) => props.theme.mixins.positionCover()};
    ${(props) =>
      props.theme.mixins.transition([
        { property: "transform" },
        { property: "opacity" },
      ])};
    opacity: ${(props) => (props.overlay ? "1" : "0")};
    z-index: 1;
    overflow: hidden;
    transform: scale(1);
    background-color: white;

    > div,
    > img {
      width: 100% !important;
      height: 100% !important;
      ${(props) => props.theme.mixins.imageObjectFit()};
      ${(props) => props.theme.mixins.positionCover()};
    }

    picture {
      &::after {
        ${(props) => props.theme.mixins.transition([{ property: "opacity" }])};
        ${(props) => props.overlay && props.theme.mixins.positionCover()};
        ${(props) => props.overlay && props.theme.mixins.overlay()};
        z-index: ${(props) => (props.overlay ? "2" : "0")};
        opacity: ${(props) => (props.overlay ? "1" : "0")};
      }
    }
  }

  &:hover .grid-image {
    opacity: ${(props) => (props.overlay ? "1" : "0")};

    ${media.md`
      transform: scale(1.01);
      opacity: 1;
    `}

    picture {
      &::after {
        opacity: 1;

        ${media.md`
          opacity: 0 !important;
        `}
      }
    }
  }

  .grid-content {
    ${(props) => props.theme.mixins.positionCover()};
    ${(props) => props.theme.mixins.transition([{ property: "opacity" }])};
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: ${(props) => props.theme.spacing.normal};
    ${media.md`
      padding: ${(props) => props.theme.spacing.large};
    `}
    text-align: center;
    color: ${(props) =>
      props.overlay ? props.theme.colors.light : props.theme.colors.dark};
  }

  &:hover .grid-content {
    color: ${(props) =>
      props.image ? props.theme.colors.light : props.theme.colors.dark};

    ${media.md`
      opacity: ${(props) => (props.image ? "0" : "1")};
      color: ${(props) =>
        props.image ? props.theme.colors.light : props.theme.colors.dark};
    `}
  }

  .grid-title,
  .grid-label {
    ${(props) => props.theme.mixins.transition([{ property: "transform" }])};
    transform: translateY(0);
  }

  &:hover .grid-title {
    ${media.md`
      transform: translateY(-25%);
    `}
  }

  &:hover .grid-label {
    ${media.md`
      transform: translateY(25%);
    `}
  }

  .Grid__Icon {
    ${(props) => props.theme.mixins.transition([{ property: "transform" }])};
    transform: scale(1);
  }

  &:hover .Grid__Icon {
    transform: scale(1.1);
  }
`;

GridStyles.defaultProps = {
  mb: 2,
};

export default GridStyles;
