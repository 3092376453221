import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import { useStaticQuery, graphql } from "gatsby";

import getOgImage from "src/helpers/getOgImage";

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  ogImage,
  summary,
  home = false,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaImage = getOgImage(ogImage);
  const metaSiteTitle = site?.siteMetadata?.title
    ? site.siteMetadata.title
    : "";

  const metaTitle =
    (home || !title) && metaSiteTitle ? metaSiteTitle : title ? title : "";
  const metaDescription =
    (home || !description) && site?.siteMetadata?.description
      ? site.siteMetadata.description
      : description
      ? description
      : "";
  const metaAuthor = site?.siteMetadata?.author ? site.siteMetadata.author : "";
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={home ? metaSiteTitle : `%s — ${metaSiteTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: description,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  );
}

export default SEO;

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};
