import React from "react";
import Text from "src/components/UI/Text";

const Title = ({ children, ...rest }) => {
  return (
    <Text
      lineHeight={["1.25em", null, "1.5em"]}
      fontSize={["6vw", "3vw", null, null, "2.5vw", "2.3vw"]}
      fontWeight="500"
      {...rest}
    >
      {children}
    </Text>
  );
};

export default Title;
