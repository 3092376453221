import styled from "styled-components";
import Box from "src/components/UI/Structure/Box";

const Content = styled(Box)`
  position: relative;
  z-index: 1;
  background-color: white;
`;

export default Content;
