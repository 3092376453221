import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Image from "src/components/UI/Image";
import Box from "src/components/UI/Structure/Box";
import HeroStyles from "src/components/styles/HeroStyles";
import Button from "src/components/UI/Button";
import Text from "src/components/UI/Text";
import Label from "src/components/UI/Typography/Label";
import Grid from "src/components/UI/Grid/Grid";
import Column from "src/components/UI/Grid/Column";

const Title = styled(Text)`
  margin: 0;
`;
class Hero extends Component {
  componentDidMount = () => {
    if (this.props.headerColor) {
      if (this.props.headerColor === "dark") {
        document.body.classList.add("header-dark");
      } else {
        document.body.classList.remove("header-dark");
      }
    }
  };
  render() {
    const {
      images,
      title,
      titleExtra,
      link,
      arrow,
      linkTitle,
      label,
      small,
      size,
      buttonContent,
      headerColor,
      extraBelow,
      largeHeadline = false,
      buttonLayout = {
        color: "white",
        fontSize: "14px",
        fontWeight: "300",
        width: 1,
        filled: null,
      },
      ...rest
    } = this.props;

    return (
      <HeroStyles
        pt={images ? 15 : [15, null, 15, 20]}
        pb={(images && [4, null, 14, 19]) || [11, null, 14, 19]}
        px={4}
        size={size}
        alignItems={["flex-end", null, "center"]}
        justifyContent="center"
        width={1}
        images={images ? images : null}
        {...rest}
      >
        {images && (
          <div className="hero-image">
            <Image image={images ? images : null} />
          </div>
        )}
        <Grid height={["100%", null, "auto"]} width={1} zIndex="3">
          <Column
            width={[12 / 12, null, 7 / 12]}
            ml={[0, null, null, (1 / 12) * 100 + "%"]}
          >
            <Grid>
              <Box width={1} mb={[extraBelow, null, 0]}>
                {label && <Label>{label}</Label>}
                <Title
                  as="h1"
                  lineHeight={!largeHeadline ? "1.4em" : "1.1em"}
                  fontSize={
                    !largeHeadline
                      ? ["30px", null, "5vw", "3.125vw"]
                      : ["60px", null, "9vw", "7vw", "8.35vw"]
                  }
                  {...rest}
                >
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                  {titleExtra && (
                    <Text fontSize="0.25em" ml="2">
                      {titleExtra}
                    </Text>
                  )}
                </Title>
              </Box>
              {link && linkTitle && (
                <Box mt="3" width="auto">
                  <Button
                    size="large"
                    label={linkTitle}
                    link={link}
                    text={linkTitle}
                    light={images ? 1 : 0}
                    {...buttonLayout}
                  >
                    {buttonContent}
                  </Button>
                </Box>
              )}
            </Grid>
          </Column>
        </Grid>
      </HeroStyles>
    );
  }
}

export default Hero;

Hero.propTypes = {
  title: PropTypes.string.isRequired,
};
