const getGatsbyImage = (media) => {
  // Gatsby image - Source V2
  if (media?.localFile?.childImageSharp?.fluid) {
    return media.localFile.childImageSharp.fluid;
  }
  // Gatsby image - Wordpress Thumbnail -  Source V3
  if (media?.node?.localFile?.childImageSharp?.fluid) {
    return media.node.localFile.childImageSharp.fluid;
  }
  // Gatsby plugin image
  if (media?.localFile?.childImageSharp?.gatsbyImageData) {
    return media.localFile.childImageSharp.gatsbyImageData;
  }
  // Gatsby plugin image - Wordpress thumbnail
  if (media?.node?.localFile?.childImageSharp?.gatsbyImageData) {
    return media.node.localFile.childImageSharp.gatsbyImageData;
  }
  return null;
};

export default getGatsbyImage;
