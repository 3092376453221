import getGatsbyImage from "src/helpers/getGatsbyImage";
import getPreviewImage from "src/helpers/getPreviewImage";

const getImageObject = (media) => {
  if (!media) {
    return null;
  }

  if (typeof media === "string") {
    return getPreviewImage(media);
  }

  if (media.url && typeof media.url === "string") {
    return getPreviewImage(media);
  }

  return getGatsbyImage(media);
};

export default getImageObject;
