const getOgImage = media => {
  if (!media) {
    return null
  }

  if (typeof media === 'string') {
    return media
  }

  return media.src
}

export default getOgImage
